/* Layouts */
const Layout = () => import("@/layout/index.vue");
/* Layouts */

/* Authenticated Component */
const Contact = () => import("@/views/user-supports/Contact.vue");
/* Authenticated Component */

export default [
    {
        path: "/user-supports/",
        component: Layout,
        alwaysShow: true, // will always show the root menu
        meta: {
            title: "User Support",
        },
        children: [
            {
                name: "user-supports-contact",
                path: "contact",
                component: Contact,
                meta: {
                    title: "Contact",
                    elSvgIcon: "Fold",
                    affix: true,
                },
            },
        ],
    },
];
